import {
  IonAvatar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useParams } from "react-router";
import SearchInput from "../../components/SearchInput/SearchInput";
import { shieldCheckmarkOutline } from "ionicons/icons";
import "./Wallet.css";
import Tokens from "../../components/Tokens/Tokens";

const Wallet: React.FC = () => {
  const { name } = useParams<{ name: string }>();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow class="ion-justify-content-center">
            <IonCol></IonCol>
            <IonCol class="search">
              <SearchInput />
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid>
          <IonRow class="ion-justify-content-center">
            <IonCol size="3"></IonCol>
            <IonCol size="auto">
              <IonCard color="primary">
                <IonCardContent>
                  <IonGrid>
                    <IonRow class="ion-justify-content-start">
                      <IonCol size="2">
                        <div>
                          <IonAvatar>
                            <img
                              alt="Silhouette of a person's head"
                              src="https://ionicframework.com/docs/img/demos/avatar.svg"
                            />
                          </IonAvatar>
                        </div>
                      </IonCol>
                      <IonCol>
                        <div>
                          <h1>NeniDevelop | Blockchain developer</h1>
                          <h1>
                            @patrikodevelop{" "}
                            <IonIcon icon={shieldCheckmarkOutline}></IonIcon>{" "}
                            #113,065
                          </h1>
                        </div>
                      </IonCol>

                      <IonCol size="2">
                        <div>
                          <h1>230$</h1>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size="3"></IonCol>
          </IonRow>
        </IonGrid>

        <IonIcon name="logo-ionic" size="large"></IonIcon>
        <IonText color="secondary" className="ion-text-center">
          <h1>Deposit SOL into your new wallet</h1>
        </IonText>

        <IonGrid>
          <IonRow class="ion-justify-content-center">
            <IonCol></IonCol>
            <IonCol class="search">
              <IonCard color="primary">
                <IonCardContent className="ion-text-center">
                  <h2>8yFANcsfL7a4W2U9EWSgi3SPx5j3ZYpKvcoW4dm5AdN4</h2>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
        </IonGrid>

        <IonSegment value="tokens" className="ion-padding-top">
          <IonSegmentButton value="tokens">
            <IonLabel>Tokens</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="transactions">
            <IonLabel>Transactions</IonLabel>
          </IonSegmentButton>
        </IonSegment>

        <Tokens></Tokens>
      </IonContent>
    </IonPage>
  );
};

export default Wallet;
