import {
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonText,
  IonThumbnail,
} from "@ionic/react";
import "./Tokens.css";
import { useState } from "react";

const Tokens: React.FC = () => {
  return (
    <>
      <IonGrid>
        <IonRow class="ion-justify-content-center">
          <IonCol>
            <div className="ion-padding-top ion-float-right">
              <IonThumbnail>
                <img
                  alt="Silhouette of mountains"
                  src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
                />
              </IonThumbnail>
            </div>
          </IonCol>
          <IonCol className="ion-padding-start">
            <div>
              <h1>Solana</h1>0.24 SOL
            </div>
          </IonCol>
          <IonCol>
            <h1>1.23$</h1>
          </IonCol>
        </IonRow>

        
        <IonRow class="ion-justify-content-center">
          <IonCol>
            <div className="ion-padding-top ion-float-right">
              <IonThumbnail>
                <img
                  alt="Silhouette of mountains"
                  src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
                />
              </IonThumbnail>
            </div>
          </IonCol>
          <IonCol className="ion-padding-start">
            <div>
              <h1>USD Coin</h1>0 USDC
            </div>
          </IonCol>
          <IonCol>
            <h1>0$</h1>
          </IonCol>
        </IonRow>

        
        <IonRow class="ion-justify-content-center">
          <IonCol>
            <div className="ion-padding-top ion-float-right">
              <IonThumbnail>
                <img
                  alt="Silhouette of mountains"
                  src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
                />
              </IonThumbnail>
            </div>
          </IonCol>
          <IonCol className="ion-padding-start">
            <div>
              <h1>Samoyed Coin</h1>0 SAMO
            </div>
          </IonCol>
          <IonCol>
            <h1>0$</h1>
          </IonCol>
        </IonRow>

        
        <IonRow class="ion-justify-content-center">
          <IonCol>
            <div className="ion-padding-top ion-float-right">
              <IonThumbnail>
                <img
                  alt="Silhouette of mountains"
                  src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
                />
              </IonThumbnail>
            </div>
          </IonCol>
          <IonCol className="ion-padding-start">
            <div>
              <h1>Woof</h1>0 WOOF
            </div>
          </IonCol>
          <IonCol>
            <h1>0$</h1>
          </IonCol>
        </IonRow>


      </IonGrid>
      <IonList>

        <br></br>
        <br></br>
        <br></br>
      </IonList>
    </>
  );
};

export default Tokens;
