import './Faq.css';
  
interface ContainerProps {
  name: string;
}


const Faq: React.FC<ContainerProps> = ({ name }) => {

  
  return (
    <div className="container">
      <strong>{name}</strong>
      <p>======= F A Q ======</p>

    
    </div>
  );
};

export default Faq;