import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonItem,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Tokens.css";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import TopToolBar from "../../components/TopToolBar/TopToolBar";
import { UserProfileContext } from "../Login/context";
import Hotpair from "../../components/Hotpair/Hotpair";
import Hotnft from "../../components/Hotnft/Hotnft";
import { useDefaultTokens } from "../../hooks/tokens/hooks";
import React from "react";
import ModalReceive from "../../components/ModalReceive/ModalReceive";
import ModalSend from "../../components/ModalSend/ModalSend";
import SelectBlockchain from "../../components/SelectBlockchain/SelectBlockchain";
import useTokenBalance from '../../hooks/tokens/useTokenBalance'
import { useTokensList } from "../../hooks/tokens/useTokensList";
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from "../../utils/formatBalance";

const Tokens: React.FC = () => {
  const { userProfile, setUserProfile } = useContext(UserProfileContext);

  const handleSearch = (event: any) => {
    console.log("=== handleSearch ===", event.target.value);
  };

  const [visible, setVisible] = useState(false);
  const handleToggle = () => {
    setVisible((current) => !current);
  };

  const tokensData = useDefaultTokens();


  useEffect(() => {
    console.log("==== userProfile  token  ===", userProfile);    
  }, [userProfile]);



  const tokenList = useTokensList(userProfile);

  
  // ok const userPoolBalance = useTokenBalance("0x2e5f9eE7D3f660E5410F11336232A1DC31Bac922")
  // console.log('==== tokens ====', userPoolBalance);

  return (
    <IonPage>
      <IonHeader>
        <TopToolBar items={userProfile}></TopToolBar>
      </IonHeader>

      <IonContent fullscreen>
        <div className="container-content-page">
          <Hotpair></Hotpair>
          <Hotnft></Hotnft>

          {/* BEGIN: FILTER */}
          <IonGrid className="grid-tokens-header">
            <IonRow className="ion-justify-content-center row-token-header">
              <IonCol
                className="col-border col-center table-token-col"
                size="3"
              >
                Portfolio
              </IonCol>
              <IonCol className="col-border col-end" size="9">
                <IonSearchbar
                  className="searchbar"
                  onChange={handleSearch}
                ></IonSearchbar>
                <SelectBlockchain></SelectBlockchain>
              </IonCol>
            </IonRow>
            <IonRow className="row-token-header">
              <IonCol size="12">
                <img
                  className="img-size"
                  src="/assets/tokens/line-token.svg"
                  alt=""
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          {/* END: FILTER */}

          {/* BEGIN: TABLE TOKENS */}
          <IonGrid className="grid-tokens">
            <IonRow className="ion-justify-content-center row-token-header">
              <IonCol>
                <IonText className="table-token-head table-token-head-col">
                  TOKEN
                </IonText>
              </IonCol>
              <IonCol className="ion-padding-start">
                <IonText className="table-token-head">PRICE</IonText>
              </IonCol>
              <IonCol>
                <IonText className="table-token-head">Volume 24hr</IonText>
              </IonCol>
              <IonCol>
                <IonText className="table-token-head">HOLDINGS</IonText>
              </IonCol>
              <IonCol>
                <IonText className="table-token-head">Value</IonText>
              </IonCol>
            </IonRow>

            {/* BEGIN: TABLE TOKENS CONTENT */}
            {tokensData.map((token, index) => (
              <IonRow
                key={token.symbol}
                onClick={() => handleToggle()}
                className={"ion-justify-content-center row-token has-details token-row " + (index+1 === tokensData.length? 'row-tokens-rounded' : '')}
              >
                <IonCol className="col-token">
                  <div className="ion-padding-top ion-float-left flex-col-token table-token-col">
                    <IonThumbnail className="thumbToken">
                      <img
                        alt={token.name}
                        src={`/assets/tokens/${token.symbol}.png`}
                      />
                    </IonThumbnail>
                    <div className="token-symbol">
                      <IonText>{token.symbol}</IonText>
                    </div>
                  </div>
                </IonCol>
                <IonCol className="col-balance-value">$ {token.price}</IonCol>
                <IonCol className="col-balance-value">
                  {token.holding}
                  <span className="details">
                    <ModalSend token={token}></ModalSend>
                    <ModalReceive token={token}></ModalReceive>
                  </span>
                </IonCol>
                <IonCol className="col-balance-value">1.03</IonCol>
                <IonCol className="col-balance-value">
                  $312.87
                  {/* }
                <ModalSendReceive token={token}></ModalSendReceive>
                <ModalReceive token={token}></ModalReceive>
                {*/}
                </IonCol>
              </IonRow>
            ))}
            {/* END: TABLE TOKENS CONTENT */}
          </IonGrid>
          {/* BEGIN: TABLE TOKENS */}
        </div>
        <br></br><br></br><br></br><br></br>
==========

      <br></br>

      {tokenList.length === 0 ? (
          <>loading
          </>
        ) : (
          tokenList.map(post => (
            <>
            <span key={post.symbol}>{post.symbol} -  
            
            {getBalanceNumber(post.balance, 0)}
            
            </span>
            <br></br>
            </>
          ))
        )}
============


      </IonContent>
    </IonPage>
  );
};

export default Tokens;