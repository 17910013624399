import Select from "react-select";
import "./SelectToken.css";
import { useDefaultTokens } from "../../hooks/tokens/hooks";
import { Tokens} from "../../hooks/tokens/hooks";
import { useEffect, useState } from "react";

interface SelectTokenProps {
  def: Tokens;
}

const SelectToken: React.FC<SelectTokenProps> = ({ def }) => {



  const customStyles = {
    container: (base: any) => ({
      ...base,
      display: 'inline-block',
      width: '-webkit-fill-available',
    }),
    control: (base: any, state: any) => ({
      ...base,
      background: "#1c1d1b",
      //backgroundColor: state.isSelected ? "#1c1d1b" : "#1c1d1b",
      backgroundColor: state.isSelected ? "#cccccc" : "#1c1d1b",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 0,
      borderRadius: 10,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#cccccc" : "#1c1d1b",
      // Removes weird border around container
      boxShadow: state.isFocused ? "#cccccc" : "#1c1d1b",
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#cccccc" : "#1c1d1b",
      },
    }),
    menu: (base: any) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      background: "#1c1d1b",
    }),
    option: (base: any, state: any) => {
      return {
        ...base,
        backgroundColor: "#1c1d1b",
        color: "#1c1d1b",
        "&:hover": {
          // Overwrittes the different states of border
          borderColor: state.isFocused ? "#cccccc" : "#1c1d1b",
        },
      };
    },
  };

  const tokensData = useDefaultTokens();

  return (
    <Select
      styles={customStyles}
      options={tokensData}
      placeholder={"Select token"}
      defaultValue={def}
      formatOptionLabel={(token) => (
        <div className="container-select">
          <img src={`/assets/tokens/${token.symbol}.png`} alt={token.name} />
          <span className="select-label-text">{token.name}</span>
        </div>
      )}
    />
  );
};

export default SelectToken;
