import BigNumber from 'bignumber.js'

const BIG_TEN = new BigNumber(10)

export const getBalanceAmount = (amount: BigNumber, decimals = 18) => {
    return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals));
}
  
  export const getFullDisplayBalance = (balance: BigNumber, decimals = 18, displayDecimals: number) => {
    return getBalanceAmount(balance, decimals).toFixed(displayDecimals)
  }

  export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2) => {
    const options = {
      minimumFractionDigits: minPrecision,
      maximumFractionDigits: maxPrecision,
    }
    return number.toLocaleString(undefined, options)
  }

  export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
    return getBalanceAmount(balance, decimals).toNumber()
  }