import { DEFAULT_LIST_HOT_NFT, DEFAULT_LIST_HOT_PAIR } from "./list";


export function useDefaultHotPair(): TrendingPair[] {
    return DEFAULT_LIST_HOT_PAIR
}

export function useDefaultHotNft(): TrendingPair[] {
    return DEFAULT_LIST_HOT_NFT
}


export interface TrendingPair {
    label: string;
    href:  string;
}