import { useEffect } from "react";

import {
    IonSpinner
  } from "@ionic/react";

interface Props {
    socialpal_authurl: string
    newwallet_redirect: string
    login_redirect: string
}

export const TwitterLoginController = ({
    socialpal_authurl,  
    newwallet_redirect,
    login_redirect  
  }: Props) => {
      useEffect(() => {
        const abortController = new AbortController();
        const authWindorURL = new URL(window.location.href);
        const uuid = authWindorURL.searchParams.get('auth_uuid')
        const code = authWindorURL.searchParams.get('code');
        const state = authWindorURL.searchParams.get('state');
        
        if(state && code && uuid)
        {
          const auth_url = `${socialpal_authurl}/process/?auth_uuid=${uuid}&state=${state}&code=${code}`;
          const data =  fetch(auth_url, {
            method: 'GET',
            mode: 'cors',
            signal: abortController.signal
          })
            .then(data => data.json())
            .then( DataObj =>  {
              if(!DataObj.hasOwnProperty("status"))
              { 
                  window.location.href = "/"
              }
              else if(!DataObj.status)
              {
                if(DataObj.hasOwnProperty("message") && DataObj.message.hasOwnProperty("error"))
                {
                  //TBD
                  window.location.href = "/"
                }
              }
              else if(DataObj.status && DataObj.hasOwnProperty("message"))
              {
                localStorage.setItem('socialpal-auth-data', JSON.stringify(DataObj))
                if(DataObj.message.userdata.seed)
                {
                  window.location.href = newwallet_redirect
                }
                else
                {
                  window.location.href = login_redirect
                }
              }
            }
          )
        }
        return () => {
          abortController.abort();
        };
      }, []);
      return (
        <IonSpinner/>
      );
}

export default TwitterLoginController