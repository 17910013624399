import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import { useParams } from "react-router";
import "./Home.css";
import { Link } from "react-router-dom";
import TopToolBar from "../../components/TopToolBar/TopToolBar";
import { getUserInfo } from "../Login/helpers";
import { useContext, useEffect } from "react";
import { UserProfileContext } from "../Login/context";

const Home: React.FC = () => {
  // do not remove const { name } = useParams<{ name: string }>();
  /*
  const { name } = useParams<{ name: string }>();
  const items = getUserInfo();
  */
  const { userProfile, setUserProfile } = useContext(UserProfileContext);

  useEffect(() => {
    console.log("==== userProfile  home  ===", userProfile);
  }, [userProfile]);

  return (
    <IonPage>
      <IonHeader>
        <TopToolBar items={userProfile}></TopToolBar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="container-content-home">
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonText>
                  <h1 className="home-h1">Everyone Has A Wallet</h1>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText className="home-content">
                  Activate your blockchain wallet with your social media
                  account.
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>

          <br />
          <IonGrid className="grid-create-wallet">
            <IonRow>
              <IonCol class="col-wallet">
                <Link to="/page/create-wallet">
                  <IonButton className="button-wallet">Create Wallet</IonButton>
                </Link>
              </IonCol>
              <IonCol class="col-info">
                <IonButton class="button-info">More info</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          <br></br>
          <br></br>
          <IonGrid className="grid-home">
            <IonRow>
              <IonCol className="home-block-column">
                <IonCard class="card-home">
                  <IonCardHeader>
                    <IonCardTitle class="card-title-home">
                      Socials Supported
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent className="home-block-card">
                    <IonImg
                      class="card-img-twitter"
                      src="/assets/home-twitter.png"
                      alt="twitter"
                    ></IonImg>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol className="home-block-column">
                <IonCard class="card-home">
                  <IonCardHeader>
                    <IonCardTitle class="card-title-home">
                      Blockchains
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent className="home-block-card">
                    <h1>6</h1>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol className="home-block-column">
                <IonCard class="card-home">
                  <IonCardHeader>
                    <IonCardTitle class="card-title-home">
                      Total Users
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent className="home-block-card">
                    <h1>45k</h1>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol className="home-block-column">
                <IonCard class="card-home">
                  <IonCardHeader>
                    <IonCardTitle class="card-title-home">
                      Partners
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent className="home-block-card">
                    <h1>Icons</h1>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
