import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonGrid, IonRow, IonCol, IonText, IonButton } from '@ionic/react';
import './CreateWalletExpertStep1.css';
import { MouseEventHandler, useState } from 'react';

interface Props {
    clickActionTransfer: (step: number) => MouseEventHandler;
}

const CreateWalletExpertStep1: React.FC<Props> = ({clickActionTransfer}) => {
    const [active, setActive] = useState(0);
  
  return (
    <IonCard class="container-card-expert">
    <IonCardHeader>
      <IonCardTitle className="title-wallet-expert">
        <h1 className="h1-card">Generate seeds phrase</h1>
      </IonCardTitle>
    </IonCardHeader>
    <IonCardContent>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonText>
              Seeds phrase is the<br></br>............ ............
              ............ ............ ............ ............
              ............ ............ ............ ............
              ............ ............
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText>
              Socialpal will never access<br></br>............
              ............ ............ ............ ............
              ............ ............ ............ ............
              ............ ............ ............
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton class="button-generate-step-1" onClick={clickActionTransfer(1)} >GENERATE</IonButton>
            <br></br>
            <IonText>What is the seed phrase?</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCardContent>
  </IonCard>
  );
};

export default CreateWalletExpertStep1;