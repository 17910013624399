import './Wallet.css';
  
interface ContainerProps {
  name: string;
}


const Wallet: React.FC<ContainerProps> = ({ name }) => {

  
  return (
    <div className="container">
      <strong>{name}</strong>
      <p>======= W A L L E T======</p>

    
    </div>
  );
};

export default Wallet;