import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonList,
  IonLabel,
  InputChangeEventDetail,
  useIonToast,
  IonIcon,
} from "@ionic/react";
import "./CreateWalletStep3.css";
import {
  ChangeEvent,
  FormEvent,
  FormEventHandler,
  MouseEventHandler,
  Ref,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { getRandomNumber } from "../helperWallet";
import { chevronBackOutline, checkmarkOutline } from "ionicons/icons";
import { WALLET_VALIDATE_SEED } from "../../../config/constants/endpoints";
import { getAuthToken, getUserInfo } from "../../../pages/Login/helpers";

interface Props {
  clickActionTransfer: (step: number) => MouseEventHandler;
  seedPhrase: string;
}

const CreateWalletStep3: React.FC<Props> = ({
  clickActionTransfer,
  seedPhrase,
}) => {
  const history = useHistory();

  const [present] = useIonToast();

  const seedPhraseArray = seedPhrase.split(" ");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const [seedPhraseVerify, setSeedPhraseVerify] = useState<string[]>([]);

  const [seedPhraseIndex, setSeedPhraseIndex] = useState<number[]>([]);

  useEffect(() => {
    // Have a array of number with 4 random number
    while (seedPhraseIndex.length < 4) {
      const randomNumber = getRandomNumber(23);

      if (!seedPhraseIndex.includes(randomNumber)) {
        seedPhraseIndex.push(randomNumber);
      }
    }

    // Add seed to array seedPhraseVerify
    seedPhraseArray.map((_seed, index) => {
      if (!seedPhraseIndex.includes(index)) {
        seedPhraseVerify[index] = seedPhraseArray[index];
      }
    });

    setLoading(false);
  }, []);

  const updateFieldChanged = (e: any, index: number) => {
    setMessage(e.target.value);

    seedPhraseVerify[index] = e.target.value;
  };

  const validateWallet = () => {
//
//    if (
//      (
//        seedPhraseArray.length === seedPhraseVerify.length &&
//        seedPhraseArray.every(
//          (value, index) => value === seedPhraseVerify[index]
//        )
//      )
//    ) {

      // call api
      const abortController = new AbortController();

      const postData = {
        seed: [
          { index: (Math.ceil(seedPhraseIndex[0])+1).toString(), value: seedPhraseVerify[seedPhraseIndex[0]] },
          { index: (Math.ceil(seedPhraseIndex[1])+1).toString(), value: seedPhraseVerify[seedPhraseIndex[1]] },
          { index: (Math.ceil(seedPhraseIndex[2])+1).toString(), value: seedPhraseVerify[seedPhraseIndex[2]] },
          { index: (Math.ceil(seedPhraseIndex[3])+1).toString(), value: seedPhraseVerify[seedPhraseIndex[3]] },
        ],
      };
      const auth_token = getAuthToken();
      
      // TODO MOVE
      const data = fetch(WALLET_VALIDATE_SEED, {
        method: "POST",
        mode: "cors",
        headers: {
          "Authorization": "Token " + auth_token,
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(postData),
        signal: abortController.signal,
      })
        .then((data) => {
          console.log(data);
          return data.json();
        })
        .then((dataObj) => {
          console.log("DataObj", dataObj);

          if (!dataObj.hasOwnProperty("status")) {
            present({
              message: "Error, please try later",
              duration: 1500,
              color: "danger",
              position: "bottom",
            });
          } else if (!dataObj.status) {
            present({
              message: "The seedphrase is not correct, please retry again.",
              duration: 1500,
              color: "danger",
              position: "bottom",
            });
          } else if (dataObj.status) {
            clickActionTransfer(3);
          }
        })
        .catch((error) => {
          console.log(error);
          present({
            message: "Error, please try later",
            duration: 1500,
            color: "danger",
            position: "bottom",
          });
        });

      return () => {
        abortController.abort();
      };
    // } else {
    //   present({
    //    message: "The seedphrase is not correct, please retry again.",
    //    duration: 1500,
    //    color: "danger",
    //    position: "bottom",
    //  });
    // }

  };

  function getRandomInt(min : number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  return (
    <>
      <IonText>
        <h1 className="text-h1">Store seed phrase</h1>
      </IonText>
      <IonGrid>
        <IonRow>
          <IonCol className="col-card">
            <IonCard className="container-card">
              <IonCardHeader>
                <IonCardTitle className="title-wallet-expert">
                  <h1 className="h1-card">
                    <IonIcon
                      className="icon-back"
                      icon={chevronBackOutline}
                      onClick={clickActionTransfer(1)}
                    ></IonIcon>{" "}
                    Backup Seed Phrase
                  </h1>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonText>
                  Enter the correct word required at the corresponding number.
                </IonText>
                <IonGrid class="grid-card-step3">
                  <IonRow>
                    {!loading &&
                      seedPhraseArray.map((seed, index: number) => {
                        return (
                          <IonCol key={index + 1} size-xs="5" size-md="3">
                            <div
                              className={
                                seedPhraseIndex.includes(index)
                                  ? "rectangle-input rectangle-flex"
                                  : "rectangle rectangle-flex"
                              }
                            >
                              <div className="number valign-text-middle">
                                {index + 1}
                              </div>
                              <img
                                className="line valign-text-middle"
                                src="/assets/wallet/line-42.svg"
                                alt="Line"
                              />
                              {seedPhraseIndex.includes(index) ? (
                                <IonInput
                                  className="input-seed"
                                  placeholder="..................."
                                  value={seedPhraseVerify[index]}
                                  onIonChange={(e) =>
                                    updateFieldChanged(e, index)
                                  }
                                ></IonInput>
                              ) : (
                                <div className="labs valign-text-middle">
                                  <img
                                    className="line check-mark valign-text-middle"
                                    src="/assets/wallet/line-42.svg"
                                    alt=""
                                  />
                                  <IonIcon
                                    className="check-mark-icon"
                                    icon={checkmarkOutline}
                                  ></IonIcon>
                                </div>
                              )}
                            </div>
                          </IonCol>
                        );
                      })}
                  </IonRow>
                </IonGrid>
                <br></br>
                <br></br>
                <br></br>
                <IonButton
                  className="button-store-seed"
                  onClick={validateWallet}
                >
                  FINISH
                </IonButton>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default CreateWalletStep3;
