import { IonInput, IonItem } from "@ionic/react";
import "./SearchInput.css";
import { useState } from "react";


const SearchInput: React.FC = () => {
  const [message, setMessage] = useState("");

  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };

  return (
      <IonItem class="roundedInput">
        <IonInput
          class="custom-input"
          placeholder="Search any username"
          onIonChange={handleChange}
          id="message"
          name="message"
          value={message}
        ></IonInput>
      </IonItem>
  );
};

export default SearchInput;
