import { DEFAULT_LIST_BLOCKCHAINS } from "./list";


export function useDefaultBlockchain(): Blockchains[] {
    return DEFAULT_LIST_BLOCKCHAINS;
}

export interface Blockchains {
    name: string;
    symbol: string;
}