import { DEFAULT_LIST_TOKENS } from "./list";

export function useDefaultTokens(): Tokens[] {
    return DEFAULT_LIST_TOKENS
}

export function useDefaultBlockchain(): Tokens[] {
    return DEFAULT_LIST_TOKENS
}


export function useCurrentPriceByToken(symbol: string): number {
    return 333;
}


export function useTokenVariation(symbol: string): number {
    return 3;
}


export interface Tokens {
    name: string;
    symbol: string;
    chain: string;
    price:  number;
    volume: string;
    holding: number;
}