import TwitterLoginController from "../../components/SocialPal/Login/Twitter/Controller"


const AuthController: React.FC = () => {
    
    return (
        <TwitterLoginController 
            socialpal_authurl={process.env.REACT_APP_SOCIALPAL_AUTHURL || ""}
            newwallet_redirect={process.env.REACT_APP_NEWACC_REDIRECT_URI || "/page/create-wallet"}
            login_redirect={process.env.REACT_APP_LOGIN_REDIRECT_URI || "/page/token" }
            />
        )
};
        
export default AuthController;
        