

export const getUserInfo = () => {
      const socialpalAuthData = localStorage.getItem('socialpal-auth-data')
      if (socialpalAuthData)
      {
        const socialPalItems = JSON.parse(socialpalAuthData)
        if(socialPalItems.hasOwnProperty("message"))
        {
          const userItems = socialPalItems.message
          if (userItems.userdata)
          {
              return userItems.userdata
          }
        }
      }
  }
  
  export const getAuthToken = () => {
    const socialpalAuthData = localStorage.getItem('socialpal-auth-data')
    if (socialpalAuthData)
    {
      const socialPalItems = JSON.parse(socialpalAuthData)
      if (socialPalItems.hasOwnProperty("message")){
        const userItems = socialPalItems.message
        if (userItems.auth_token) {
            return userItems.auth_token
        }
      }
    }
  
}