import React, { Dispatch, SetStateAction } from 'react'

export const UserProfileContext = React.createContext<{
  isUserEnabled: boolean
  setIsUserEnabled: Dispatch<SetStateAction<boolean>> | null
  userProfile: any
  setUserProfile: Dispatch<SetStateAction<any>> | undefined
  isToogleBalance: boolean
  setIsToogleBalance: Dispatch<SetStateAction<boolean>> | null
}>({
  isUserEnabled: false,
  setIsUserEnabled: null,
  userProfile: {},
  setUserProfile: undefined,
  isToogleBalance: false,
  setIsToogleBalance: null
})
