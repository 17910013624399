/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 *
 * LoginSocialTwitter
 *
 */
import React, { memo, useCallback, useEffect, useRef } from 'react';

interface Props {
  className?: string;
  socialpal_authurl?: string;
  children?: React.ReactNode;
  isLogin?: boolean;
  onLoginStart?: () => void;
  onLogoutSuccess?: () => void;
  onReject: (reject: string | objectType) => void;
  onResolve: ({ provider, data }: IResolveParams) => void;
}

export const TwitterLogin = ({
  children,
  socialpal_authurl,
  className = '',
  onLoginStart,
}: Props) => { 
  
  const onLogin = useCallback(async () => {
    
    onLoginStart && onLoginStart();
    localStorage.removeItem('socialpal-auth-data')

    const initurl_response = await fetch(`${socialpal_authurl}/init/`, {
        method: 'GET',
        mode: 'cors'
      }).then(data => data.json())
    const jsonData = initurl_response
    
    if ( jsonData.status === false)
        return false;

    const tw_authurl = jsonData.message.auth_url
    window.open(
        tw_authurl,
        '_self',
      );
  }, [
    onLoginStart,
  ]);

  return (
    <div className={className} onClick={onLogin}>
      {children}
    </div>
  );
};

export type objectType = {
    [key: string]: any;
  };
  
  export type IResolveParams = {
    provider: string;
    data?: objectType;
  };


export default memo(TwitterLogin);
