  import './ComponentOne.css';
  
  interface ContainerProps {
    name: string;
  }

  
const ComponentOne: React.FC<ContainerProps> = ({ name }) => {

    
    return (
      <div className="container">
        <strong>{name}</strong>
        <p>============ component one</p>

      
      </div>
    );
};
  
export default ComponentOne;