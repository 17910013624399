import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonIcon,
  IonButton,
} from "@ionic/react";
import { warningOutline } from "ionicons/icons";
import "./CreateWalletStep1.css";
import { MouseEventHandler, useContext, useEffect } from "react";
import { UserProfileContext } from "../../../pages/Login/context";
import { getUserInfo } from "../../../pages/Login/helpers";

interface Props {
  clickActionTransfer: (step: number) => MouseEventHandler;
}


const CreateWalletStep1: React.FC<Props> = ({clickActionTransfer}) => {

  const { userProfile, setUserProfile } = useContext(UserProfileContext);
  
  return (
    <>
      <IonText>
        <h1 className="text-h1">Congrats {userProfile.username}</h1>
      </IonText>
      <IonGrid>
        <IonRow>
          <IonCol className="col-card">
            <IonCard className="container-card">
              <IonCardContent>
                <IonGrid class="grid-card">
                  <IonRow>
                    <IonCol className="col-content-card">
                      <IonText>
                        You can now send and receive tokens with your Twitter
                        handle:
                      </IonText>
                      <br></br>
                      <h1 className="text-h1">@{userProfile.social_handle}</h1>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="col-content-card">
                      <IonCard class="card-create-wallet">
                        <IonCardContent>
                          <IonText>
                            <IonIcon icon={warningOutline}></IonIcon> &nbsp; We
                            recommend using 2FA for your Twitter account to
                            improve your security.
                          </IonText>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <br></br>
                <br></br>
                <br></br>
                <IonButton className="button-store-seed" onClick={clickActionTransfer(1)}>
                  Store your seed phrase
                </IonButton>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default CreateWalletStep1;
