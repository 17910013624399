import { memo } from 'react';
import './CardProfile.css';
import { IonAvatar, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { shieldCheckmarkOutline } from "ionicons/icons";

interface Props {
    profile: any
    provider: string
    onLogout: () => void
  }

const CardProfile: React.FC<Props> = memo(({ provider, profile, onLogout }: Props) => {
  
  return (
    <div className="container">
      <IonGrid>
          <IonRow class="ion-justify-content-center">
            <IonCol size="3"></IonCol>
            <IonCol size="auto">
              <IonCard color="primary">
                <IonCardContent>
                  <IonGrid>
                    <IonRow class="ion-justify-content-start">
                      <IonCol size="2">
                        <div>
                          <IonAvatar>
                            <img
                              alt="Silhouette of a person's head"
                              src={profile.profile_image_url}
                            />
                          </IonAvatar>
                        </div>
                      </IonCol>
                      <IonCol>
                        <div>
                          <h1>{profile.name}</h1>
                          <h1>
                            @{profile.username}{" "}
                            <IonIcon icon={shieldCheckmarkOutline}></IonIcon>{" "}
                            #113,065
                          </h1>
                        </div>
                      </IonCol>

                      <IonCol size="2">
                        <div>
                          <h1>230$</h1>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size="3"></IonCol>
          </IonRow>
        </IonGrid>
    
    </div>
  );
});

export default CardProfile;