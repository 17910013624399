import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Page.css';
import ComponentOne from '../components/ComponentOne/ComponentOne';
import SearchInput from '../components/SearchInput/SearchInput';
import Wallet from '../components/Wallet/Wallet';
import Faq from '../components/Faq/Faq';

const Page: React.FC = () => {

  const { name } = useParams<{ name: string; }>();

  const loadComponent = () => {
    switch(name) {

      case "ComponentOne":   return <ComponentOne name={name}/>;
      case "home":   return <SearchInput/>;
      case "wallet":   return <Wallet name={name}/>;
      case "faq":   return <Faq name={name}/>;

      default:      return <ExploreContainer name={name}/>
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        { loadComponent() }
        
        { /*}
        <ExploreContainer name={name} />
        { */}
      </IonContent>
    </IonPage>
  );
};

export default Page;
