import {
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonImg,
} from "@ionic/react";
import { eyeOffOutline } from "ionicons/icons";
import "./TopBar.css";
import TopBarConnected from "../TopBarConnected/TopBarConnected";



const TopBar: React.FC = () => {

  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonMenuButton />
      </IonButtons>
      { /*} 
      <TopBarConnected/>
  { */}
    </IonToolbar>
  );
};

export default TopBar;
