import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonIcon,
  IonButton,
  IonContent,
  IonInput,
} from "@ionic/react";
import Select from "react-select";
import { sendOutline, warningOutline } from "ionicons/icons";
import "./ModalSendStep1.css";
import { MouseEventHandler } from "react";
import { Tokens} from "../../../hooks/tokens/hooks";
import { useFollowerTwitter } from "../../../hooks/twitter/hooks";
import SelectToken from "../../SelectToken/SelectToken";

interface Props {
  clickActionTransfer: (step: number) => MouseEventHandler;
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  token: Tokens;
}

const ModalSendStep1: React.FC<Props> = ({
  clickActionTransfer,
  onDismiss,
  token,
}) => {
  const followersData = useFollowerTwitter();
  function handleFollower(handle: string): void {
    console.log("=== handleFollower ===", handle);
  }

  return (
    <>
      {/* BEGIN: Modal SEND STEP1 CONTENT */}
      <IonContent className="primary-text">
        <div className="header-receive">
          <IonText className="header-receive-text primary-text header">
            SEND
          </IonText>
          <div className="close-container">
            <IonText
              className="close-text"
              onClick={() => onDismiss(null, "cancel")}
            >
              X
            </IonText>
          </div>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol className="select-token-col">
              <SelectToken def={token}></SelectToken>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="primary-text ion-text-start">
              <IonText className="text-send-secondary">To</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInput
                className="input-address"
                placeholder="Address or handle..."
              ></IonInput>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonText className="text-send-secondary text-twitter">Twitter</IonText>
        <IonGrid className="modal-twitter-text seed-scroll">
          {followersData.map((follower, index) => (
            <IonRow key={index}  onClick={() => handleFollower(follower.handle)} className="list-follower">
              <IonCol className="activity-col" size="auto">
                <img
                  alt="profile"
                  src={follower.profileImgUrl}
                  width={"50px"}
                />
              </IonCol>
              <IonCol className="activity-col">
                {follower.name}
                <br></br>
                {follower.handle}
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
        <br></br>
        <div className="container-send-invite">
          <IonText className="text-send-invite">
            Send invite via Socials
          </IonText>
          <IonIcon
            icon={sendOutline}
            size="small"
            className="icon-send-invite"
          ></IonIcon>
        </div>
        <div className="container-bottom">
          <IonButton
            className="button-send-cancel"
            onClick={() => onDismiss(null, "cancel")}
          >
            Cancel
          </IonButton>
          <IonButton
            className="button-send-continue"
            onClick={clickActionTransfer(1)}
          >
            Continue
          </IonButton>
        </div>
      </IonContent>
      {/* END: BEGIN: Modal SEND STEP1 CONTENT */}
    </>
  );
};

export default ModalSendStep1;
