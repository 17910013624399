import {
  IonContent,
  IonHeader,
  IonPage,
  IonText,
} from "@ionic/react";
import { Step, Stepper } from "react-form-stepper";
import "./CreateWalletExpert.css";
import TopBar from "../../components/TopBar/TopBar";
import { useEffect, useState } from "react";
import CreateWalletExpertStep1 from "../../components/CreateWalletExpert/CreateWalletExpertStep1/CreateWalletExpertStep1";
import CreateWalletExpertStep2 from "../../components/CreateWalletExpert/CreateWalletExpertStep2/CreateWalletExpertStep2";
import CreateWalletExpertStep3 from "../../components/CreateWalletExpert/CreateWalletExpertStep3/CreateWalletExpertStep3";



const CreateWalletExpert: React.FC = () => {
  const [active, setActive] = useState(0);


  const handleStepClicked = (step: number) => {
    return (event: React.MouseEvent) => {
      setActive(step);
    };
  };

  return (
    <IonPage>
      <IonHeader>
        <TopBar></TopBar>
      </IonHeader>

      <IonContent fullscreen>
        <IonText>
          <h1 className="text-h1">Create your Wallet</h1>
        </IonText>

        <div className="container-stepper">
          <Stepper activeStep={active}>
            <Step
              className="stepper-create-wallet"
              onClick={() => setActive(0)}
            />
            <Step
              className="stepper-create-wallet"
              onClick={() => setActive(1)}
            />
            <Step
              className="stepper-create-wallet"
              onClick={() => setActive(2)}
            />
          </Stepper>
        </div>

        {/* } Step 1 { */}
        {active === 0 ? (
          <div>
            <CreateWalletExpertStep1 clickActionTransfer={handleStepClicked} />
          </div>
        ) : active === 1 ? (
          <div>
            <CreateWalletExpertStep2 clickActionTransfer={handleStepClicked} />
          </div>
        ) : (
          <CreateWalletExpertStep3 clickActionTransfer={handleStepClicked} />
        )}

      </IonContent>
    </IonPage>
  );
};

export default CreateWalletExpert;


