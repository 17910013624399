import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonText,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Login.css";
import { useCallback, useContext, useState } from "react";
import { IResolveParams } from "reactjs-social-login";
import TwitterLogin, {
} from "../../components/SocialPal/Login/Twitter/Page";
import { TwitterLoginButton } from "react-social-login-buttons";
import TopToolBar from "../../components/TopToolBar/TopToolBar";
import { getUserInfo } from "./helpers";
import { UserProfileContext } from "./context";

const Login: React.FC = () => {

  useIonViewWillEnter(() => {
    // console.log('init');
  });
  const [provider, setProvider] = useState("");

  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProvider("");
    // alert("logout success");
  }, []);

  function handleClick(): void {
    console.log("=== handleClick ===");
  }

  const { userProfile, setUserProfile } = useContext(UserProfileContext);

  return (
    <>
      <IonPage>
        <IonHeader>
          <TopToolBar items={userProfile}></TopToolBar>
        </IonHeader>

        <IonContent fullscreen>
          <IonText>
            <h1 className="text-h1">Login to your Wallet</h1>
          </IonText>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <IonCard class="container-card-login">
            <IonCardContent>
              {provider && userProfile ? (
                <>CONNECTED !</>
              ) : (
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <TwitterLogin
                        onLoginStart={onLoginStart}
                        onLogoutSuccess={onLogoutSuccess}
                        socialpal_authurl={
                          process.env.REACT_APP_SOCIALPAL_AUTHURL || ""
                        }
                        isLogin={true}
                        onResolve={({ provider, data }: IResolveParams) => {
                          setProvider(provider);
                          localStorage.setItem("user", JSON.stringify(data));
                          if (setUserProfile) {
                            setUserProfile(getUserInfo());
                          }
                        }}
                        onReject={(err: any) => {
                          console.log(err);
                        }}
                      >
                        <TwitterLoginButton
                          align="center"
                          text="Sign in with twitter"
                          className="button-twitter"
                        />
                      </TwitterLogin>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>OR</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton className="button-login" onClick={handleClick}>
                        Access your wallet with a seed phrase
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
            </IonCardContent>
          </IonCard>
          <br></br>
          <br></br>
          <br></br>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Login;
