import BigNumber from "bignumber.js";
import { getBep20Contract } from "../../utils/contractHelpers";


export const getBalanceFromAddress = async (addressToken: string, addressAccount: string): Promise<BigNumber> => {
  console.log('==== debug 02 ====');
    // addressToken = "0x2e5f9eE7D3f660E5410F11336232A1DC31Bac922";
    
    
    const contract = getBep20Contract(addressToken);
    try {
      console.log('==== debug 03 ====');
      const res = await contract.balanceOf(addressAccount);
      console.log('==== debug 04 ====');
      const balance =  new BigNumber(res._hex);

      return balance;
    } catch (e) {
      console.error(e);
    }

    return new BigNumber(0);

  }


