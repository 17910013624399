import {
  IonContent,
  IonFooter,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { useHistory, useLocation } from "react-router-dom";
import {
  homeOutline,
  homeSharp,
  colorFilterOutline,
  colorFilterSharp,
  documentOutline,
  peopleOutline,
  documentSharp,
  peopleSharp,
  caretDownOutline,
  caretDownSharp,
  logoTwitter,
  sendSharp,
  sendOutline,
  logOutSharp,
  logOutOutline,
} from "ionicons/icons";

import { Tooltip } from "react-tooltip";

import "./Menu.css";
import { useState } from "react";
import React from "react";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: "Home",
    url: "/page/home",
    iosIcon: homeOutline,
    mdIcon: homeSharp,
  },
  {
    title: "Token",
    url: "/page/token",
    iosIcon: colorFilterOutline,
    mdIcon: colorFilterSharp,
  },
];

interface MenuProps {
  handleLogout: () => void;
  profile: any;
}

const Menu: React.FC<MenuProps> = ({ handleLogout, profile }) => {
  const location = useLocation();
  const [isToggled, toggle] = useState(false);

  const handleToogleSocial = () => {
    toggle(!isToggled);
  };

  const forceToogleSocial = () => {
    toggle(false);
  };

  const useOutsideClick = (callback: any) => {
    const ref = React.useRef<HTMLIonMenuToggleElement>(null);

    React.useEffect(() => {
      const handleClick = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, [ref]);

    return ref;
  };

  const ref = useOutsideClick(forceToogleSocial);

  const history = useHistory();

  return (
    <IonMenu contentId="main" type="overlay" class="custom-input">
      <IonContent>
        <IonList id="inbox-list" class="menu-list">
          <IonListHeader className="menu-padding">
            <h1 className="title">
              <div className="container-logo">
                <IonImg
                  className="img-logo"
                  src="/assets/logo2.png"
                  alt="logo"
                ></IonImg>
                <span className="logo0 text-logo">SOCIAL</span>
                <span className="logo1 text-logo">PAL</span>
              </div>
            </h1>
          </IonListHeader>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle
                key={index}
                autoHide={false}
                className="menu-padding"
              >
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel className="menu-text">{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <IonNote></IonNote>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonTitle>
            <div className="footer-menu">
              <div>
                <span
                  data-tooltip-id="my-tooltip-children-multiline"
                  ref={ref}
                  onClick={handleToogleSocial}
                  className={isToggled ? "socials-menu" : "socials-menu-hover"}
                >
                  {/*}
                  <IonIcon slot="start" ios={peopleOutline} md={peopleSharp} />{" "}
        {*/}
                  <div className="container-social-menu">
                    <IonImg
                      src="/assets/icon/icon-social.png"
                      className="icon-social"
                      alt="social"
                    ></IonImg>
                    <IonText>&nbsp; Socials</IonText>
                    <IonIcon
                      slot="start"
                      className="icon-caret"
                      ios={caretDownOutline}
                      md={caretDownSharp}
                    />
                  </div>
                </span>

                <Tooltip id="my-tooltip-children-multiline" openOnClick={true}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    <a
                      href="https://twitter.com/SocialPal_Web3"
                      target="_blank"
                      rel="noreferrer"
                      className="social-link-a"
                    >
                      <div className="social-link">
                        <IonImg
                          src="/assets/icon/icon-twitter.png"
                          className="icon-social"
                          alt="fire"
                        ></IonImg>{" "}
                        <div className="social-link-text">Twitter</div>
                      </div>
                    </a>
                    <a
                      href="http://www.telegram.com"
                      target="_blank"
                      className="social-link-a"
                      rel="noreferrer"
                    >
                      <div className="social-link">
                        <IonImg
                          src="/assets/icon/icon-telegram.png"
                          className="icon-social"
                          alt="icon telegram"
                        ></IonImg>{" "}
                        <div className="social-link-text">Telegram</div>
                      </div>
                    </a>
                  </div>
                </Tooltip>
              </div>
              <div className="menu-docs">
                <IonIcon
                  slot="start"
                  ios={documentOutline}
                  md={documentSharp}
                />{" "}
                &nbsp; Docs
              </div>

              {profile && (
                <div className="menu-docs">
                  <IonIcon
                    slot="start"
                    className="disconnect-button"
                    onClick={handleLogout}
                    icon={logOutOutline}
                  ></IonIcon>
                  &nbsp;{" "}
                  <span className="disconnect-button" onClick={handleLogout}>
                    Logout
                  </span>
                </div>
              )}
            </div>
          </IonTitle>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
