import { IonButton } from "@ionic/react";
import "./TopBarNotConnected.css";
import { Link } from "react-router-dom";

const TopBarNotConnected: React.FC = () => {
  return (
    <div className="container-button">
      
      <Link to="/page/login" ><IonButton className="button-connect"><span className="button-sign-in">Sign in</span></IonButton></Link>

    </div>
  );
};

export default TopBarNotConnected;
