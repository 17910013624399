import { IonIcon, IonImg, IonText } from "@ionic/react";
import { eyeOffOutline, eyeOutline, logOutOutline } from "ionicons/icons";
import "./TopBarConnected.css";
import { memo, useContext } from "react";
import { UserProfileContext } from "../../pages/Login/context";

interface TopBarConnectedProps {
  handleLogout: () => void;
  profile: any;
  provider: string;
}

const TopBarConnected: React.FC<TopBarConnectedProps> = memo(


  ({ handleLogout, provider, profile }: TopBarConnectedProps) => {

    const { isToogleBalance, setIsToogleBalance } = useContext(UserProfileContext);

    const handleToogleBalance = () => {
      if (setIsToogleBalance)  {
        console.log('==== setIsUserEnabledsetIsUserEnabled ===');
        setIsToogleBalance(!isToogleBalance);
      }
    };

    return (
      <div className="title-header">
        {isToogleBalance ? (
          <IonIcon className="icon-eye"
            slot="start"
            icon={eyeOffOutline}
            onClick={handleToogleBalance}
          ></IonIcon>
        ) : (
          <IonIcon className="icon-eye"
            slot="start"
            icon={eyeOutline}
            onClick={handleToogleBalance}
          ></IonIcon>
        )}

        <div>
          Balance<br></br>
          {isToogleBalance ? <IonText>$ 1200</IonText> : <IonText>$ -</IonText>}
        </div>
        <div>
          <IonImg
            className="img-profile"
            src={profile.profile_img_url}
            alt="profile"
          ></IonImg>
        </div>
        <div>
          {profile.username}
          <br></br>
          <span className="header-handler">@{profile.social_handle}</span>
        </div>
        <IonIcon
          slot="start"
          size="large"
          className="disconnect-button"
          icon={logOutOutline}
          onClick={handleLogout}
        ></IonIcon>
      </div>
    );
  }
);

export default TopBarConnected;
