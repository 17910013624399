import { IonToolbar, IonButtons, IonMenuButton } from "@ionic/react";
import { useHistory } from "react-router-dom";
import TopBarConnected from "../TopBarConnected/TopBarConnected";
import TopBarNotConnected from "../TopBarNotConnected/TopBarNotConnected";
import "./TopToolBar.css";
import { useContext, useEffect, useState } from "react";
import { UserProfileContext } from "../../pages/Login/context";
interface TopToolBarProps {
  items: any;
}

const TopToolBar: React.FC<TopToolBarProps> = ({ items }) => {
  const [provider, setProvider] = useState("");
  const history = useHistory();

  
  const { isUserEnabled, setIsUserEnabled } = useContext(UserProfileContext);
  const { userProfile, setUserProfile } = useContext(UserProfileContext);

  const handleLogout = () => {
    items = undefined;
    setProvider("");
    if(setUserProfile) {
      setUserProfile(undefined);
    } 
    localStorage.clear();
    history.push(`/page/home`);
  };

  useEffect(() => {
  }, [isUserEnabled, userProfile])

  return (
    <div>
      {items && items.id ? (
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <TopBarConnected
            handleLogout={handleLogout}
            provider={provider}
            profile={items}
          />
        </IonToolbar>
      ) : (
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <TopBarNotConnected />
        </IonToolbar>
      )}
    </div>
  );
};

export default TopToolBar;
