import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonButton,
} from "@ionic/react";
import "./CreateWalletExpertStep2.css";
import { MouseEventHandler, useState } from "react";

interface Props {
  clickActionTransfer: (step: number) => MouseEventHandler;
}

const CreateWalletExpertStep2: React.FC<Props> = ({ clickActionTransfer }) => {
  const [active, setActive] = useState(0);

  return (
    <IonCard class="container-card-expert">
      <IonCardHeader>
        <IonCardTitle className="title-wallet-expert">
          <h1 className="h1-card">Backup Seed Phrase</h1>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText>
                Write down your seed phrase. Do not screenshot or send your
                seeds phrase to other.
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid>
          <IonRow>
            <IonCol className="colSeed">
              <IonText>1 labs
              </IonText>
            </IonCol>
            <IonCol className="colSeed">
              <IonText>2 labs
              </IonText>
            </IonCol>
            <IonCol className="colSeed">
              <IonText>3 labs
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="colSeed">
              <IonText>4 labs
              </IonText>
            </IonCol>
            <IonCol className="colSeed">
              <IonText>5 labs
              </IonText>
            </IonCol>
            <IonCol className="colSeed">
              <IonText>6 labs
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="colSeed">
              <IonText>7 labs
              </IonText>
            </IonCol>
            <IonCol className="colSeed">
              <IonText>8 labs
              </IonText>
            </IonCol>
            <IonCol className="colSeed">
              <IonText>9 labs
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="colSeed">
              <IonText>10 labs
              </IonText>
            </IonCol>
            <IonCol className="colSeed">
              <IonText>11 labs
              </IonText>
            </IonCol>
            <IonCol className="colSeed">
              <IonText>12 labs
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton
                class="button-generate-step-1"
                onClick={clickActionTransfer(2)}
              >
                NEXT
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default CreateWalletExpertStep2;
