import {
  LoginSocialTwitter,
  IResolveParams,
} from 'reactjs-social-login';

import {
  TwitterLoginButton
} from 'react-social-login-buttons';

import './ExploreContainer.css';
import { useCallback, useState } from 'react';
import { User } from './User';
import CardProfile from './CardProfile/CardProfile';

interface ContainerProps {
  name: string;
}

const REDIRECT_URI = 'https://smooth-boats-wait-84-5-25-2.loca.lt/page/login';


const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {

  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState<any>();

  const onLoginStart = useCallback(() => {
    //alert('login start');
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider('');
    alert('logout success');
  }, []);

  const onLogout = useCallback(() => {}, []);
  
  return (
    <div className="container">
      <strong>{name}</strong>
      <p>Explore <a target="_blank" rel="noopener noreferrer" href="https://ionicframework.com/docs/components">UI Components</a></p>
    
      {provider && profile ? (
        <>
        <CardProfile provider={provider} profile={profile} onLogout={onLogoutSuccess}/></>
      ) : (
      <LoginSocialTwitter
          client_id={process.env.REACT_APP_TWITTER_V2_APP_KEY || ''}
          redirect_uri={REDIRECT_URI}
          onLoginStart={onLoginStart}
          onLogoutSuccess={onLogoutSuccess}
          onResolve={({ provider, data }: IResolveParams) => {
            setProvider(provider);
            setProfile(data);
          }}
          onReject={(err: any) => {
            console.log(err);
          }}
        >
          <TwitterLoginButton />
        </LoginSocialTwitter>
      )}
    
    </div>
  );
};

export default ExploreContainer;
