import { TrendingPair } from "./hooks";


export const DEFAULT_LIST_HOT_PAIR: TrendingPair[] = [
    {
      label: "#1 NRCH",
      href: "https://www.google.com",
    },
    {
      label: "#2 ENBL",
      href: "https://www.google.com",
    },
    {
      label: "#3 KAKI",
      href: "https://www.google.com",
    },
    {
      label: "#4 SQUA",
      href: "https://www.google.com",
    },
    {
      label: "#5 GMM",
      href: "https://www.google.com",
    },
    {
      label: "#6 IM",
      href: "https://www.google.com",
    },
    {
      label: "#7 ULX",
      href: "https://www.google.com",
    },
    {
      label: "#8 WBNB",
      href: "https://www.google.com",
    },
    {
      label: "#9 WBTC",
      href: "https://www.google.com",
    },
    {
      label: "#10 SPPI",
      href: "https://www.google.com",
    },
  ]

export const DEFAULT_LIST_HOT_NFT: TrendingPair[] = [
    {
      label: "#1 TUD",
      href: "https://www.google.com",
    },
    {
      label: "#2 PNAT",
      href: "https://www.google.com",
    },
    {
      label: "#3 OMAP",
      href: "https://www.google.com",
    },
    {
      label: "#4 HERO",
      href: "https://www.google.com",
    },
    {
      label: "#5 FUSI",
      href: "https://www.google.com",
    },
    {
      label: "#6 ANON",
      href: "https://www.google.com",
    },
    {
      label: "#7 WILD",
      href: "https://www.google.com",
    },
    {
      label: "#8 WIE",
      href: "https://www.google.com",
    },
    {
      label: "#9 CWAR",
      href: "https://www.google.com",
    },
    {
      label: "#10 CR7",
      href: "https://www.google.com",
    },
  ]
