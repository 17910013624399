import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import { checkmarkDoneCircleOutline } from "ionicons/icons";
import "./CreateWallet.css";
import TopBar from "../../components/TopBar/TopBar";
import { useState, useContext, useCallback, useEffect } from "react";
import { UserProfileContext } from "../Login/context";
import CreateWalletStep1 from "../../components/CreateWallet/CreateWalletStep1/CreateWalletStep1";
import { Stepper, Step } from "react-form-stepper";
import CreateWalletStep2 from "../../components/CreateWallet/CreateWalletStep2/CreateWalletStep2";
import CreateWalletStep3 from "../../components/CreateWallet/CreateWalletStep3/CreateWalletStep3";
import { TwitterLoginButton } from "react-social-login-buttons";
import { IResolveParams } from "reactjs-social-login";
import LoginTwitter from "../../components/SocialPal/Login/Twitter/Page";
import { getUserInfo } from "../Login/helpers";
import CreateWalletStep4 from "../../components/CreateWallet/CreateWalletStep4/CreateWalletStep4";

const CreateWallet: React.FC = () => {
  const [provider, setProvider] = useState("");
  const { userProfile, setUserProfile } = useContext(UserProfileContext);

  const [active, setActive] = useState(0);

  const [seedPhrase, setSeedPhrase] = useState("");

  useEffect(() => {
    const user = getUserInfo();
    if (user) {
      console.log('==== create wallet useEffect setSeedPhrase ====');
      setSeedPhrase(user.seed);
    }
    
  }, [])

  const handleStepClicked = (step: number) => {
    if (step === 3) {
      setActive(4);
    }
    return () => {
      setActive(step);
    };
  };

  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProvider("");
    // alert("logout success");
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <TopBar></TopBar>
      </IonHeader>

      <IonContent fullscreen>
        {(userProfile) ? (
          <>
            <div className="container-stepper">
              <Stepper activeStep={active}>
                <Step
                  className="stepper-create-wallet"
                  onClick={() => setActive(0)}
                />
                <Step
                  className="stepper-create-wallet"
                  onClick={() => setActive(1)}
                />
                <Step
                  className="stepper-create-wallet"
                  onClick={() => setActive(2)}
                />
              </Stepper>
            </div>

            {/* } Step 1 { */}
            {active === 0 ? (
              <div>
                <CreateWalletStep1 clickActionTransfer={handleStepClicked} />
              </div>
            ) : active === 1 ? (
              <div>
                {/* } Step 2 { */}
                <CreateWalletStep2
                  seedPhrase={seedPhrase}
                  clickActionTransfer={handleStepClicked}
                />
              </div>
            ) : active === 2 ? (
              <div>
                {/* } Step 3 { */}
                <CreateWalletStep3
                  seedPhrase={seedPhrase}
                  clickActionTransfer={handleStepClicked}
                />
              </div>
            ) : (
              <CreateWalletStep4
                clickActionTransfer={handleStepClicked}
              />
            )}
          </>
        ) : (
          <>
            <IonText>
              <h1 className="text-h1">Sign in to your Wallet</h1>
            </IonText>
            <IonGrid>
              <IonRow>
                <IonCol className="col-card">
                  <IonCard className="container-card">
                    <IonCardHeader>
                      <IonCardTitle>
                        <h1 className="h1-card">A Wallet for Everyone</h1>
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonGrid class="grid-card">
                        <IonRow>
                          <IonCol className="col-content-card">
                            <IonText>
                              <IonIcon
                                icon={checkmarkDoneCircleOutline}
                              ></IonIcon>{" "}
                              &nbsp; User Friendly: Your Twitter handle is your
                              wallet address.
                            </IonText>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol className="col-content-card">
                            <IonText>
                              <IonIcon
                                icon={checkmarkDoneCircleOutline}
                              ></IonIcon>{" "}
                              &nbsp; User Friendly: Your Twitter handle is your
                              wallet address. Social recovery: No risk of losing
                              access to your wallet. You can recover access to
                              your wallet anytime.
                            </IonText>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                      <br></br>
                      <br></br>
                      <br></br>
                      <LoginTwitter
                        onLoginStart={onLoginStart}
                        onLogoutSuccess={onLogoutSuccess}
                        socialpal_authurl={
                          process.env.REACT_APP_SOCIALPAL_AUTHURL || ""
                        }
                        isLogin={false}
                        onResolve={({ provider, data }: IResolveParams) => {
                          /*
                          if (setUserProfile) {
                            setUserProfile(getUserInfo());
                          }
                          */
                        }}
                        onReject={(err: any) => {
                          console.log(err);
                        }}
                      >
                        <TwitterLoginButton
                          align="center"
                          text="Sign in with twitter"
                          className="button-twitter"
                        />
                      </LoginTwitter>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default CreateWallet;
