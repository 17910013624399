import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, useHistory } from "react-router-dom";
import Menu from "./components/Menu";
import Page from "./pages/Page";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import AuthController from "./pages/AuthController/AuthController";
import PageOne from "./pages/PageOne/PageOne";
import Home from "./pages/Home/Home";
import Wallet from "./pages/Wallet/Wallet";
import Login from "./pages/Login/Login";
import CreateWallet from "./pages/CreateWallet/CreateWallet";
import CreateWalletExpert from "./pages/CreateWalletExpert/CreateWalletExpert";
import Tokens from "./pages/Tokens/Tokens";
import { UserProfileContext } from "./pages/Login/context";
import { useEffect, useState } from "react";
import { getUserInfo } from "./pages/Login/helpers";

setupIonicReact();

const App: React.FC = () => {
  const [isUserEnabled, setIsUserEnabled] = useState(false);
  const [isToogleBalance, setIsToogleBalance] = useState(true);
  const [userProfile, setUserProfile] = useState();

  const history = useHistory();

  useEffect(() => {
    setUserProfile(getUserInfo());
    setIsUserEnabled(true);
  }, [])

  const handleLogout = () => {

    if(setUserProfile) {
      setUserProfile(undefined);
    } 
    localStorage.clear();
    history.push(`/page/home`);
  };

  return (
    <UserProfileContext.Provider value={{ isUserEnabled, setIsUserEnabled, userProfile, setUserProfile, isToogleBalance, setIsToogleBalance }}>
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu handleLogout={handleLogout} profile={userProfile}/>
            <IonRouterOutlet id="main">
              <Route path="/" exact={true}>
                <Redirect to="/page/home" />
              </Route>

              <Route path="/auth-controller" exact={true}>
                <AuthController />
              </Route>
              <Route path="/page/:name" exact={true}>
                <Page />
              </Route>
              <Route path="/page/home" exact={true}>
                <Home />
              </Route>

              <Route path="/page/token" exact={true}>
                <Tokens />
              </Route>

              <Route path="/page/create-wallet" exact={true}>
                <CreateWallet />
              </Route>
              <Route path="/page/create-wallet-expert" exact={true}>
                <CreateWalletExpert />
              </Route>

              <Route path="/page/wallet" exact={true}>
                <Wallet />
              </Route>

              <Route path="/page/login" exact={true}>
                <Login />
              </Route>

              <Route path="/page/PageOne" exact={true}>
                <PageOne />
              </Route>
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    </UserProfileContext.Provider>
  );
};

export default App;
