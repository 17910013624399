import Select from "react-select";
import "./SelectBlockchain.css";
import {
  useDefaultBlockchain,
} from "../../hooks/blockchains/hooks";
import { useRef} from "react";
import { useIonViewWillLeave } from "@ionic/react";

const SelectBlockchain: React.FC = () => {
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: "#262626",
      backgroundColor: state.isSelected ? "#cccccc" : "#262626",
      // match with the menu
      borderRadius: 10,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#cccccc" : "#262626",
      // Removes weird border around container
      boxShadow: state.isFocused ? "#cccccc" : "#262626",
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#cccccc" : "#262626",
      },
    }),
    menu: (base: any) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      background: "#1c1d1b",
    }),
    option: (base: any, state: any) => {
      return {
        ...base,
        backgroundColor: "#1c1d1b",
        color: "#1c1d1b",
        "&:hover": {
          // Overwrittes the different states of border
          borderColor: state.isFocused ? "#cccccc" : "#1c1d1b",
        },
      };
    },
  };

  const tokensData = useDefaultBlockchain();

  const selectInputRef = useRef<any>(null);

  useIonViewWillLeave(() => {
    selectInputRef.current.clearValue();
  });

  const handleChange = (event: any) => {
    // console.log('==== event ====', event);
  };

  return (
    <div className="select-blockchain">
      <Select
        styles={customStyles}
        options={tokensData}
        ref={selectInputRef}
        onChange={handleChange}
        isClearable
        placeholder={"Blockchain"}
        formatOptionLabel={(token) => (
          <div className="container-select">
            <img src={`/assets/tokens/${token.symbol}.png`} alt={token.name} />
            <span className="select-label-text">{token.name}</span>
          </div>
        )}
      />
    </div>
  );
};

export default SelectBlockchain;
