import { IonImg } from "@ionic/react";
import { Link } from "react-router-dom";
import "./Hotpair.css";
import { useDefaultHotPair } from "../../hooks/trending/hooks";

const Hotpair: React.FC = () => {
  const hotpairsData = useDefaultHotPair();

  return (
    <div className="container-trending container-trending-round-up">
      <div className="label-trending">Hot Pairs</div>
      <IonImg
        src="/assets/icon/flash.png"
        className="icon-trending"
        alt="flash"
      ></IonImg>
      {hotpairsData.map(({ label, href }) => (
        <div className="label-pair" key={label}>
          <Link to={{ pathname: `${href}` }} target="_blank" className="pair-color">
            {label}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Hotpair;
