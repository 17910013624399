import { IonImg } from "@ionic/react";
import { Link } from "react-router-dom";
import "./Hotnft.css";
import { useDefaultHotNft } from "../../hooks/trending/hooks";

const Hotnft: React.FC = () => {
  const defaultHotNft = useDefaultHotNft();

  return (
    <div className="container-trending">
      <div className="label-trending">TOP NFTs</div>
      <IonImg
        src="/assets/icon/icon_fire.png"
        className="icon-trending"
        alt="fire"
      ></IonImg>
      {defaultHotNft.map(({ label, href }) => (
        <div className="label-pair" key={label}>
          <Link className="link-nft" to={{ pathname: `${href}` }} target="_blank">
            {label}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Hotnft;
