import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";
import "./CreateWalletStep2.css";
import { MouseEventHandler } from "react";
import { chevronBackOutline } from "ionicons/icons";

interface Props {
  clickActionTransfer: (step: number) => MouseEventHandler;
  seedPhrase: string;
}

const CreateWalletStep2: React.FC<Props> = ({
  clickActionTransfer,
  seedPhrase,
}) => {
  
  const seedPhraseArray = seedPhrase.split(" ");

  return (
    <>
      <IonText>
        <h1 className="text-h1">Store seed phrase</h1>
      </IonText>
      <IonGrid>
        <IonRow>
          <IonCol className="col-card">
            <IonCard className="container-card">
              <IonCardHeader>
                <IonCardTitle className="title-wallet-expert">
                  <h1 className="h1-card"><IonIcon className="icon-back" icon={chevronBackOutline} onClick={clickActionTransfer(0)}></IonIcon> Backup Seed Phrase</h1>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonGrid class="grid-card-seed">
                  <IonRow>
                    <IonCol className="col-content-card">
                      <IonText>
                        Write down your seed phrase. Do not screenshot or send
                        your seeds phrase to other.
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    {seedPhraseArray.map((seed, index: number) => {
                      return (
                        <IonCol key={index+1} size-xs="5" size-md="3">
                          <div className="rectangle rectangle-flex">
                            <div className="number valign-text-middle">{index + 1}</div>
                            <img
                              className="line valign-text-middle"
                              src="/assets/wallet/line-42.svg"
                              alt=""
                            />
                            <div className="labs valign-text-middle">{seed}</div>
                          </div>
                        </IonCol>
                      );
                    })}
                  </IonRow>
                </IonGrid>
                <br></br>
                <IonButton
                  className="button-copy"
                  onClick={() => {navigator.clipboard.writeText(seedPhrase)}}
                >
                  Copy to clipboard
                </IonButton>
                <br></br>
                <br></br>
                <IonButton
                  className="button-store-seed2"
                  onClick={clickActionTransfer(2)}
                >
                  NEXT
                </IonButton>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default CreateWalletStep2;
