import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonIcon,
  IonButton,
} from "@ionic/react";
import { warningOutline } from "ionicons/icons";
import "./CreateWalletStep4.css";
import { MouseEventHandler, useContext, useEffect } from "react";
import { UserProfileContext } from "../../../pages/Login/context";
import { checkmarkCircleOutline } from "ionicons/icons";

interface Props {
  clickActionTransfer: (step: number) => MouseEventHandler;
}

const CreateWalletStep4: React.FC<Props> = ({ clickActionTransfer }) => {
  const { userProfile, setUserProfile } = useContext(UserProfileContext);

  return (
    <>
      <IonText>
        <h1 className="text-h1">Congrats {userProfile.social_handle}</h1>
      </IonText>
      <IonGrid>
        <IonRow>
          <IonCol className="col-card">
            <IonCard className="container-card">
              <IonCardContent>
                <IonGrid class="grid-card-step4">
                  <IonRow>
                    <IonCol className="col-content-card">
                      <h1 className="text-h1">
                        Your wallet has been created !
                      </h1>
                      <br></br>
                      <IonIcon
                        icon={checkmarkCircleOutline}
                        className="icon-checkmark-wallet"
                        size="large"
                      ></IonIcon>
                      <br></br>
                      <h1 className="text-h1">Here your address :</h1>
                      <br></br>
                      <h1 className="text-h1">{userProfile.address}</h1>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <br></br>
                <IonButton
                  className="button-copy"
                  onClick={() => {navigator.clipboard.writeText(userProfile.address)}}
                >
                  Copy your address
                </IonButton>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default CreateWalletStep4;
