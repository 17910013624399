import React, { useState, useRef, ReactNode } from "react";
import Select from "react-select";
import {
  IonButtons,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  useIonModal,
  IonText,
  IonCol,
  IonGrid,
  IonRow,
  IonThumbnail,
  IonListHeader,
  IonSelect,
  IonSelectOption,
  IonImg,
  IonIcon,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/core/components";
import {
  Tokens,
  useDefaultTokens,
  useCurrentPriceByToken as useTokenPrice,
  useTokenVariation,
} from "../../hooks/tokens/hooks";
import { sendOutline } from "ionicons/icons";
import "./ModalSend.css";
import { useFollowerTwitter } from "../../hooks/twitter/hooks";
import ModalSendStep1 from "./ModalSendStep1/ModalSendStep1";
import ModalSendStep2 from "./ModalSendStep2/ModalSendStep2";

const ModalSendContent = ({
  onDismiss,
  token,
}: {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  token: Tokens;
}) => {

  const [active, setActive] = useState(0);

  const handleStepClicked = (step: number) => {
    if (step === 2) {
      setActive(0);
    }
    return () => {
      setActive(step);
    };
  };


  return (
    <IonPage>
      {active === 0 ? (
        <ModalSendStep1
          token={token}
          clickActionTransfer={handleStepClicked}
          onDismiss={onDismiss}
        ></ModalSendStep1>
      ) : (
        <ModalSendStep2
          token={token}
          clickActionTransfer={handleStepClicked}
          onDismiss={onDismiss}
        ></ModalSendStep2>
      )}
      {/* BEGIN: Modal Receive CONTENT */}
      {/* }
      <IonContent className="primary-text">
        <div className="header-receive">
          <IonText className="header-receive-text primary-text header">
            SEND
          </IonText>
          <div className="close-container">
            <IonText
              className="close-text"
              onClick={() => onDismiss(null, "cancel")}
            >
              X
            </IonText>
          </div>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol className="select-token-col">
              <Select
                styles={customStyles}
                options={tokensData}
                formatOptionLabel={(token) => (
                  <div className="container-select">
                    <img
                      src={`/assets/tokens/${token.symbol}.png`}
                      alt={token.name}
                    />
                    <span>{token.name}</span>
                  </div>
                )}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="primary-text">To</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInput
                className="input-address"
                placeholder="Address or handle..."
              ></IonInput>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonText className="primary-text">Twitter</IonText>
        <IonGrid className="modal-activity-text seed-scroll">
          {followersData.map((follower, index) => (
            <IonRow>
              <IonCol className="activity-col" size="auto">
                <img alt="profile" src={follower.profileImgUrl} />
              </IonCol>
              <IonCol className="activity-col">
                {follower.name}
                <br></br>
                {follower.handle}
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
        <br></br>
        <div className="container-send-invite">
          <IonText className="text-send-invite">
            Send invite via Socials
          </IonText>
          <IonIcon icon={sendOutline} size="large"></IonIcon>
        </div>
        <div className="container-bottom">
          <IonButton className="button-send-cancel">Cancel</IonButton>
          <IonButton className="button-send-continue">Continue</IonButton>
        </div>
      </IonContent>
          { */}
      {/* END: Modal Receive CONTENT */}
    </IonPage>
  );
};

function ModalSend({ token }: { token: Tokens }) {
  const [present, dismiss] = useIonModal(ModalSendContent, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
    token: token,
  });


  function openModal() {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {

      },
    });
  }

  return (
    <IonButton
      className="button-send-receive"
      expand="block"
      onClick={() => openModal()}
    >
      Send
    </IonButton>
  );
}

export default ModalSend;
