import { SetStateAction, useEffect, useState } from "react"
import useTokenBalance from "./useTokenBalance";
import { getBalanceFromAddress } from "../../state/tokens/fetchBalance";
import BigNumber from "bignumber.js";
import { getBalanceAmount } from "../../utils/formatBalance";



export const useTokensList = (profile: any): Token[] => {


  const [tokens, setTokens] = useState<Token[]>([]);


  useEffect(() => {


    const data = async () => {
       
      fetch(`https://dev-api.socialpal.io/tokens/bnb/list/`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => {
          console.log(data);
          return data.json();
        })
        .then((dataObj) => {
          console.log("DataObj", dataObj);

          if (!dataObj.hasOwnProperty("status")) {
            console.log('=== erroor ===');
          } else if (!dataObj.status) {
            console.log('=== erroor 2 ===');
          } else if (dataObj.status) {
            console.log('=== OK ===');

               
                /*
            const tokenWithBalance =  dataObj.tokens.map((token: Token) => {

              let balance = new BigNumber(0);
              if (profile) {
                  console.log('==== debug 01 ====');
               
                  getBalanceFromAddress(token.address, profile.address).then(val => {
                    console.log('==== debug 05 ====');
                    balance = val;
                    token.balance = getBalanceAmount(balance, token.decimals);
                  })
              }
              return token;
            });

                */

            const computeBalance = async (n: any) => {
              console.log('==== n ====', n);
              if (profile) {
                return await getBalanceFromAddress(n.address, profile.address);
              } 
              return new BigNumber(0);
              // console.log('==== bal ====', bal);
              // return bal;
            };

            const asyncBalance = async() => {
              const unresolvedBalancePromises = dataObj.tokens.map(computeBalance);
              console.log('==== unresolvedPromises ====', unresolvedBalancePromises);
              const resultsBalance = await Promise.all(unresolvedBalancePromises);
              console.log('==== result ====', resultsBalance);

              dataObj.tokens.map((token: any, index: number) => {
                token.balance = getBalanceAmount(resultsBalance[index], token.decimals);
              })
              
              setTokens(dataObj.tokens);
            };
               
            asyncBalance();
            

          }
        })
        .catch((error) => {
          console.log(error);
        });
      }


      
        console.log('=== reload 1 ===', tokens.length);
        if (tokens.length === 0) {
          console.log('=== reload 2 ===');
          data();
        }

  }, [profile, tokens]);


  
  console.log('==== end tokens  ====', tokens);

  return tokens;

  /*
    const [error, setError] = useState(false)

    const [tokens, setTokens] = useState<Token[]>([]);

    const abortController = new AbortController();

    const data = fetch(`https://dev-api.socialpal.io/tokens/bnb/list/`, {
        method: "GET",
        mode: "cors",
        signal: abortController.signal,
      })
        .then((data) => {
          console.log(data);
          return data.json();
        })
        .then((dataObj) => {
          console.log("DataObj", dataObj);

          if (!dataObj.hasOwnProperty("status")) {
            console.log('=== erroor ===');
          } else if (!dataObj.status) {
            console.log('=== erroor 2 ===');
          } else if (dataObj.status) {
            console.log('=== OK ===');
            setTokens(dataObj.tokens);

            abortController.abort();
          }
        })
        .catch((error) => {
          console.log(error);
        });

        return tokens;

        */
  }



  
export interface ApiTokensResponse {
    status: boolean;
    tokens: Token[];
}

export interface Token {
    symbol:    string;
    is_native: boolean;
    decimals:  number;
    address:   string;
    volume:    number;
    price:     number;
    balance:   BigNumber;
}